import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, AuthState } from '@gentext/auth-office';
import { CHAT_SERVICE } from '@gentext/chat-ui';
import { CookieConsentService } from '@gentext/cookie-consent';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { filter, map } from 'rxjs';
import { ChatUiService } from './chat-ui.service';
import { LicenseService } from './license.service';

@Component({
  selector: 'gentext-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false,
})
export class AppComponent implements OnInit, AfterViewInit {
  claritySessionId = '';
  selectedOptionPath = '';
  isAuthenticated = false;
  license$ = this.licenseService.license$;
  showManagePlan$ = this.license$.pipe(
    map((f) => f == null || f?.planId !== 'enterprise'),
  );
  authState: AuthState | undefined;
  authState$ = this.authService.authState$;
  firstName$ = this.authState$.pipe(
    map((a) => {
      const nameParts = a?.name?.split(' ');
      return nameParts?.length ? nameParts[0] : '';
    }),
  );

  greeting$ = this.firstName$.pipe(map((f) => `Welcome ${f}`));
  isLoadingAuth$ = this.authService.loading$;

  menuVisible = false;

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.cdr.detectChanges();
  }

  async signIn() {
    this.authService.refreshState();
  }

  async signOut() {
    this.chatUiService.clearMessages();
    this.toggleMenu();
    this.authService.signOut();
  }

  get isManagePlanVisible() {
    return this.router.url === '/manage-plan';
  }

  managePlan() {
    this.router.navigate(['/manage-plan']);
  }

  support() {
    const link = `mailto:support@lexdraft.ai`;
    window.open(link);
  }

  constructor(
    private authService: AuthService,
    private logging: LoggingService,
    private licenseService: LicenseService,
    private router: Router,
    @Inject(CHAT_SERVICE) private chatUiService: ChatUiService,
    private cdr: ChangeDetectorRef,
    private cookieConsentService: CookieConsentService,
  ) {}

  async ngAfterViewInit() {
    await this.cookieConsentService.init();
  }

  ngOnInit() {
    this.authService.authState$.subscribe((a) => {
      if (!a) {
        return;
      }
      this.licenseService.getLicense();
    });
    this.authService.authState$.subscribe((authState) => {
      this.isAuthenticated = !!authState;
      if (this.isAuthenticated && !window.location.href.includes('redirect')) {
        this.router.navigate(['/chat']);
      }
      this.cdr.detectChanges();
    });
    this.selectedOptionPath = window.location.pathname.substring(1);
    if (!this.selectedOptionPath.startsWith('auth')) {
      this.logging.trace({
        message: 'AppComponent: refreshing state silently.',
      });
      this.authService.getJwtFromStorageEmitAuthState();
    }
    this.authService.authState$.subscribe((authState) => {
      this.authState = authState;
      this.setClarityIdentifiers();
      this.setGtagIdentifiers();
      this.cdr.detectChanges();
    });
    this.setClarityIdentifiers();
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe(() => {
        this.setClarityIdentifiers();
        this.setGtagIdentifiers();
      });
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Check if the click is outside the menu
    const isClickInsideMenu = (event.target as HTMLElement).closest(
      '.menu-class-name',
    );

    if (!isClickInsideMenu && this.menuVisible) {
      this.toggleMenu();
      this.cdr.detectChanges(); // Update the view
    }
  }
  private setGtagIdentifiers() {
    const userId = this.authState?.email;
    if (userId) {
      // only configure gtag if user is logged in
      this.logging.trace({
        message: 'Setting gtag user id',
        properties: { userId },
      });
      gtag('config', 'G-XGVND3X8BY', {
        user_id: userId,
      });
    }
  }
  private async setClarityIdentifiers() {
    const userId = this.authState?.email;

    if (userId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clarity = (window as any)['clarity'] as any;

      if (clarity) {
        if (this.claritySessionId === '') {
          const hashCode = (s: string) =>
            s.split('').reduce((a, b) => {
              a = (a << 5) - a + b.charCodeAt(0);
              return a & a;
            }, 0);
          this.claritySessionId = `${hashCode(userId)}-${Date.now()}`;
        }
        const pageId = this.router.url;

        // API: window.clarity('identify', userId, sessionId, pageId, friendlyName)
        const clarityResponse = await clarity(
          'identify',
          userId,
          this.claritySessionId,
          pageId,
          userId,
        );
        this.logging.trace({
          message: 'Received clarity identify response',
          properties: { clarityResponse },
          severityLevel: SeverityLevel.Verbose,
        });
      }
    }
  }
}
