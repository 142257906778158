<div class="manage-container flex size-full flex-col">
  <hr class="my-10 mb-2" />
  <fluent-button
    class="my-3 flex w-full flex-row justify-between space-x-2"
    appearance="filled"
    (click)="goBack()"
    >Back</fluent-button
  >
  <p class="pb-3 text-red-500" *ngIf="hasUnexpectedError$ | async">
    Something went wrong.<br />Please contact us at
    <a href="mailto:support@lexdraft.ai">support&#64;lexdraft.ai</a>.
  </p>
  <p class="pb-3 text-red-500" *ngIf="hasBadRequestError$ | async">
    The token has expired or is invalid.<br />Please contact us at
    <a href="mailto:support@lexdraft.ai">support&#64;lexdraft.ai</a>.
  </p>

  <div
    class="text-center [&>p]:text-base"
    *ngIf="resolveResponse$ | async as r; else tmplManageSub"
  >
    <span class="grayscale-svg flex justify-center"></span>
    <h1 class="mt-4 text-2xl">Thank you for your subscription</h1>
    <p class="my-6">
      Please verify the following information is correct. If not, please contact
      us at
      <a href="mailto:support@lexdraft.ai" class="text-blue-700"
        >support&#64;lexdraft.ai</a
      >.
    </p>

    <div
      class="grid grid-cols-2 gap-2 [&>span]:text-left [&>strong]:text-right"
    >
      <strong>Plan:</strong>
      <span>
        {{ r.planType?.planId === 'premium' ? 'Premium' : 'Unknown' }}</span
      >

      <strong>Term:</strong>
      <span>
        {{ r.planType?.term === PlanTerm.Monthly ? 'Monthly' : 'Annual' }}
      </span>

      <strong>Term:</strong>
      <span *ngIf="r.autoRenews; else tmplNoAutoRenew">Auto renews</span>
      <ng-template #tmplNoAutoRenew>
        <span>Does not auto renew</span>
      </ng-template>

      <strong>End date</strong> <span>{{ r.endDate | date }}</span>

      <strong>Email address:</strong>
      <span class="break-words">{{ r.userEmail }}</span>
    </div>
  </div>
  <ng-template #tmplManageSub>
    <div class="sticky bottom-0 flex items-center justify-between">
      <div class="flex w-full flex-row justify-between space-x-2">
        <fluent-button
          class="flex-1"
          [appearance]="monthlyAppearance"
          (click)="setPlanTerm(PlanTerm.Monthly)"
          [disabled]="loading$ | async"
          >Monthly</fluent-button
        >

        <fluent-button
          class="flex-1"
          [appearance]="yearlyAppearance"
          (click)="setPlanTerm(PlanTerm.Yearly)"
          [disabled]="loading$ | async"
          >Annual</fluent-button
        >
      </div>
    </div>
    <div class="mt-2 text-center text-sm text-gray-700">
      With the annual plan, you get 2 months free!
    </div>

    <!-- two-way binding does not work with ngModelChange for some reason-->
    <fluent-radio-group
      orientation="vertical"
      class="mt-2 block w-full"
      [ngModel]="selectedPlanId"
      ngDefaultControl
    >
      <fluent-radio
        class="plan-radio relative mb-2 flex w-full flex-row rounded border px-2 py-1"
        [class]="{
          'border-gray-300': selectedPlanId !== p.planType,
          'border-purple-300': selectedPlanId === p.planType,
        }"
        *ngFor="let p of planSelections"
        [attr.data-testid]="'plan-select-' + p.planType.toLowerCase()"
        [value]="p.planType"
        [disabled]="loading$ | async"
        (click)="selectedPlanId = p.planType"
      >
        <div class="flex w-full flex-row items-center justify-between">
          <div class="inline-block">
            <p class="text-lg font-bold">{{ p.title }}</p>
            <ul class="ml-4 list-disc">
              @switch (p.planType) {
                @case ('free') {
                  <li>Words per month: Up to 2000</li>
                  <li>Can draft legal agreements</li>
                }
                @case ('premium') {
                  <li>Words per month: Up to 100,000</li>
                  <li>Can draft legal agreements</li>
                  <li>Look up US Case Law</li>
                }
                @case ('business') {
                  <li>Unlimited words</li>
                  <li>Can draft legal agreements</li>
                  <li>Look up US Case Law</li>
                }
                @case ('enterprise') {
                  <li>Unlimited words</li>
                  <li>Can draft legal agreements</li>
                  <li>Look up US Case Law</li>
                  <li>Special databases to be connected</li>
                  <li>SOC 2 certification</li>
                }
              }
            </ul>
          </div>

          <div class="whitespace-nowrap text-xl font-bold text-purple-800">
            <ng-container *ngIf="p.price !== undefined; else tmplNoPrice">
              <span
                *ngIf="p.oldPrice"
                class="text-base text-gray-400 line-through"
              >
                ${{ p.oldPrice }}</span
              >
              <span> ${{ p.price }}</span>
            </ng-container>
            <ng-template #tmplNoPrice>
              <span class="block text-right text-base">Custom <br />quote</span>
            </ng-template>
          </div>
        </div>
      </fluent-radio>
    </fluent-radio-group>

    <fluent-button
      class="w-full"
      appearance="accent"
      (click)="upgrade()"
      data-testid="change-plan-button"
      [disabled]="isManagePlanDisabled$ | async"
      >{{ changePlanButtonText }}</fluent-button
    >
    <fluent-progress-ring
      *ngIf="loading$ | async"
      class="mx-auto block w-4"
    ></fluent-progress-ring>
  </ng-template>
</div>
