import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CHAT_SERVICE, ChatMessage } from '@gentext/chat-ui';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ChatUiService } from '../chat-ui.service';
@Component({
  selector: 'gentext-chat-app',
  templateUrl: './chat-app.component.html',
  styleUrl: './chat-app.component.css',
  standalone: false,
})
export class ChatAppComponent implements OnInit {
  clearMessages$ = this.chatService.clearMessages$;
  addOrUpdateMessage$ = this.chatService.addOrUpdateMessage$;
  sendMessage$ = this.chatService.sendMessageToUi$;
  error$ = this.chatService.error$;

  private allChatSuggestions = [
    { text: 'Check Key Clauses for Missing Provisions' },
    { text: 'Summarize Changes from the Previous Draft' },
    { text: 'Review Compliance with Applicable Laws' },
    { text: 'Suggest Clear Definitions for Ambiguous Terms' },
    { text: 'Analyze Enforceability and Potential Risks' },
    { text: 'Recommend Alternative Language for Liability Clauses' },
    { text: 'Provide a List of Required Signatures and Approvals' },
    { text: 'Offer Precedent-Based Clause Options' },
    { text: 'Check for Logical Consistency in Cross-References' },
    { text: 'Generate an Executive Summary of Key Terms' },
  ];
  chatSuggestions = this.getChatSuggestions();
  private getChatSuggestions() {
    const shuffled = this.allChatSuggestions.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  }
  get chatId() {
    return this.chatService.chatId;
  }
  constructor(
    private router: Router,
    private logging: LoggingService,
    @Inject(CHAT_SERVICE) private chatService: ChatUiService,
  ) {}
  managePlan(showManagePlan: boolean) {
    if (showManagePlan) {
      this.router.navigate(['/manage-plan']);
    } else {
      this.router.navigate(['/chat']);
    }
  }

  ngOnInit() {
    this.setupListenerDocSelChanged();
  }

  private async setupListenerDocSelChanged() {
    this.logging.trace({
      message: 'Setup listener for document selection changed',
      severityLevel: SeverityLevel.Information,
    });

    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        Office.context.document.getSelectedDataAsync(
          Office.CoercionType.Text,
          (asyncResult) => {
            const message: ChatMessage = {
              id: 'rephrase_or_cite',
              text: 'Would you like to check US Case law for precedents?',
              hidden: false,
              sender: 'ai',
              isCompleted: true,
              showInsertInDocument: false,
              messageActions: [
                {
                  text: 'Check US Case law for precedents',
                  onClick: async () => {
                    try {
                      this.chatService.sendMessageToUi(
                        `Check US Case law for precedents for: ${asyncResult.value}`,
                      );
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (e: any) {
                      this.chatService.setError(e);
                    }
                  },
                },
              ],
            };
            if (
              asyncResult.status !== Office.AsyncResultStatus.Failed &&
              asyncResult.value !== ''
            ) {
              message.hidden = false;
              this.chatService.addOrUpdateMessage(message);
            } else {
              message.hidden = true;
              this.chatService.addOrUpdateMessage(message);
            }
          },
        );
      },
    );
  }
}
