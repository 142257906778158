import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BASE_PATH,
  DocumentModification,
  DocumentService,
} from '@gentext/api-client';
import { AuthService } from '@gentext/auth-office';
import {
  ChatMessage,
  ChatMessageResponse,
  ChatResponse,
  ChatService,
  ChatSystemResponse,
} from '@gentext/chat-ui';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as signalR from '@microsoft/signalr';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

// This service is DI provided in the app.module.ts using the CHAT_SERVICE token from the chat-ui library
// This way there is always only one instance of the service.
// perhaps not 100% clean, but it works.
// to use it anywhere in the app, add @Inject(CHAT_SERVICE)
@Injectable()
export class ChatUiService implements ChatService {
  private _error$ = new Subject<string>();
  private _chatId = uuidv4();
  private _clearMessages$ = new Subject<void>();
  private _addOrUpdateMessage$ = new ReplaySubject<ChatMessage>(1);
  private _sendMessageToUi$ = new Subject<string>();
  private connection!: signalR.HubConnection;
  public get chatId(): string {
    return this._chatId;
  }
  public clearMessages$ = this._clearMessages$.asObservable();
  public sendMessageToUi$ = this._sendMessageToUi$.asObservable();

  get error$(): Observable<string> {
    return this._error$.asObservable();
  }

  public clearMessages(): void {
    this._clearMessages$.next();
  }
  private hubUrl = `${this.baseUrl}/hubs/messageRelayHub`;
  addOrUpdateMessage(message: ChatMessage) {
    this._addOrUpdateMessage$.next(message);
  }
  get addOrUpdateMessage$(): Observable<ChatMessage> {
    return this._addOrUpdateMessage$.asObservable();
  }

  setError(error: string) {
    this._error$.next(error);
  }

  sendMessageToUi(message: string) {
    this._sendMessageToUi$.next(message);
  }

  sendChat(input: string, chatId: string): Observable<ChatResponse> {
    const response$ = new Subject<ChatResponse>();

    const sendMessageToConnection = () => {
      this.connection.on('data', (data) => {
        this.logging.trace({
          message: 'Data received',
          properties: {
            data,
          },
          severityLevel: SeverityLevel.Verbose,
        });
        const res: ChatMessageResponse = {
          type: 'chatMessageResponse',
          message: data,
        };
        response$.next(res);
      });
      this.connection.on('ineligibleFeature', () => {
        const res: ChatSystemResponse = {
          type: 'chatSystemResponse',
          response: 'INELIGIBLE_FEATURE_ACCESSED',
        };
        response$.next(res);
      });
      this.connection.on('done', () => {
        this.logging.trace({
          message: 'Connection signalR done',
        });
        response$.complete();
      });
      this.connection.on('error', (err) => {
        const message = this.getErrorMessage(err);
        response$.error({
          err,
          message,
        });
      });
      this.connection.invoke('SendChatAsk', input, chatId, 'en');
    };

    if (!this.connection || !this.connection.connectionId) {
      this.initialiseConnection()
        .then(() => {
          sendMessageToConnection();
        })
        .catch((err) => {
          response$.error({
            err,
            message:
              'We are sorry, but something went wrong on the AI service. Please try your request after a brief wait and contact us if the issue persists.',
          });
          response$.complete();
        });
    } else {
      sendMessageToConnection();
    }

    return response$.asObservable();
  }

  private async initialiseConnection(isInteractive = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!isInteractive && !this.auth.getCachedAccessToken()) {
        return;
      }
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.hubUrl, {
          accessTokenFactory: () => this.auth.getAccessTokenInteractive(),
        })
        .withAutomaticReconnect()
        .build();
      // TODO onreconnect

      this.connection
        .start()
        .then(() => {
          this.logging.trace({
            message: 'Chat Connection started',
          });
          resolve();
        })
        .catch((err) => {
          this.logging.exception({
            exception: err,
          });
          reject(err);
        });

      this.connection.on('document_action', (message) => {
        if (message === 'reword') {
          this.rewordDocument();
        }
      });
    });
  }
  constructor(
    private auth: AuthService,
    private logging: LoggingService,
    private documentsService: DocumentService,
    @Inject(BASE_PATH) private baseUrl: string,
  ) {
    this.initialiseConnection(false);
  }

  updateDocumentSummary(chatId: string, summary: string) {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => this.auth.getAccessTokenInteractive(),
      })
      .build();
    connection
      .start()
      .then(() => {
        this.logging.trace({
          message: 'Chat Connection started',
        });
        connection.invoke('UpdateDocumentSummary', chatId, summary);
      })
      .catch((err) => {
        this.logging.exception({
          exception: err,
        });
      });
  }
  uploadDocument(file: File, chatId: string): Observable<string> {
    const response$ = new Subject<string>();
    const supportedFileTypes = [
      'application/pdf',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'audio/mpeg',
      'audio/mp3',
      'audio/mp4',
      'audio/mpga',
      'audio/x-m4a',
      'audio/wav',
      'audio/webm',
      'audio/m4a',
    ];

    if (!supportedFileTypes.includes(file.type)) {
      response$.error(
        'Please upload a supported file type: .pdf, .txt, .docx, .mp3, .flac, .m4a, .mp4, .mpeg, .mpga, .oga, .ogg, .wav, .webm',
      );
      return response$.asObservable();
    }

    this.documentsService
      .documentUploadPost(chatId, file, 'body', true)
      .subscribe({
        next: (res) => {
          response$.next(res);
          response$.complete();
        },
        error: (err) => {
          response$.error(err.message);
          response$.complete();
        },
      });
    return response$.asObservable();
  }
  async rewordDocument() {
    Word.run(async (context) => {
      const document: Word.Document = context.document;
      document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
      await context.sync();
      const messageId = uuidv4();
      this.addOrUpdateMessage({
        isCompleted: false,
        id: messageId,
        sender: 'ai',
        showInsertInDocument: false,
        text: 'Rewording the document...',
      });
      context.document.body.load('text');
      await context.sync();
      const text = context.document.body.text;
      this.documentsService
        .documentStartDocumentActionPost({
          action: 'Reword',
          chatId: this.chatId,
          documentContents: text,
        })
        .subscribe(async (res) => {
          this.applyModifications(res.modifications);

          this.addOrUpdateMessage({
            isCompleted: true,
            id: messageId,
            sender: 'ai',
            showInsertInDocument: false,
            text: 'Applied all modifications',
          });
        });
    });
  }

  private applyModifications(modifications: Array<DocumentModification>) {
    Word.run(async (context) => {
      const uniqueModifications = modifications.filter(
        (mod, index, self) =>
          index === self.findIndex((t) => t.original === mod.original),
      );
      console.log({ uniqueModifications });
      for (const mod of uniqueModifications) {
        if (mod.original.length > 255) {
          console.error('Original text is too long to search for');
          continue;
        }
        const searchResults = context.document.search(mod.original, {
          matchCase: false,
          matchWholeWord: false,
        });
        searchResults.load('items');
        await context.sync();

        for (let i = 0; i < searchResults.items.length; i++) {
          searchResults.items[i].insertText(
            mod.replacement,
            Word.InsertLocation.replace,
          );
        }
        await context.sync();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorMessage(err: any): string {
    this.logging.trace({
      message: 'An unsuccessful response from the API has occurred.',
      properties: { err },
      severityLevel: SeverityLevel.Warning,
    });

    const httpErrorResponse = err as HttpErrorResponse;
    let message =
      'We are sorry, but something went wrong on the AI service. Please try your request after a brief wait and contact us if the issue persists.';
    const apiErrorMessage: string =
      typeof err === 'string'
        ? err
        : httpErrorResponse.error?.error?.message ||
          (typeof httpErrorResponse.error === 'string'
            ? httpErrorResponse.error
            : typeof httpErrorResponse.message === 'string'
              ? httpErrorResponse.message
              : '');

    if (
      apiErrorMessage.includes('Connection closed with an error') ||
      apiErrorMessage.includes('OPENAI_TOKEN_ERROR')
    ) {
      message = `Maximum word input length exceeded. Please select a shorter text`;
    } else if (apiErrorMessage.includes('USAGE_EXCEEDED')) {
      message = `You have exceeded your free monthly usage limit. To continue using LegalNote, please upgrade by clicking the 'Manage Plan' button at the menu`;
    } else if (apiErrorMessage.includes('OPENAI_ERROR')) {
      message = `We are sorry, but the AI service is currently facing a server outage. We are working to fix the problem as soon as possible.`;
    }

    return message;
  }
}
